import '../index.css';

function App() {
  return (
    <div className="flex h-screen justify-center items-center flex-col">
      <h1 className="text-blue-700 text-6xl">Welcome to xby. The work just started.</h1>
    </div>
  );
}

export default App;
